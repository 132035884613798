import React, { useEffect } from 'react'
import Title from '../../_common/_components/Title/Title'
import Breadcrumb from '../../_common/_components/Breadcrumb/Breadcrumb'
import Table from '../../_common/_components/Table/Table'
import useFetch from '../../_common/_Hooks/useFetch'
import useTranslate from '../../_common/_Hooks/useTranslate'
import Page from '../../_common/_components/Page/Page'
import useFilter from '../../_common/_Hooks/useFilter'
import Actions from '../../_common/_components/Actions/Actions'
import Button from '../../_common/_components/Button/Button'
import { Modals } from '../../_common/_components/ModalsContainer/ModalsContainer'
import useModal from '../../_common/_Hooks/useModal'
import { Add_Edit_IncidentType } from '../../common/Modals'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

export default function SubTypes() {

    const { id } = useParams()
    const location = useLocation()
    const isChild = location.state?.child

    const navigate = useNavigate()
    const { _lang } = useTranslate()
    const m_Add_Edit_IncidentType = useModal(<Add_Edit_IncidentType isSubType={!isChild} isChild={isChild} />)

    // *********************************************************************************************************************
    const { req: getDetails, res: details_res } = useFetch({
        endpoint: "incidentType/getsubIncidentTypePagination",
        type: 'raw',
        method: "post",
        // selfCall: isChild ? false : id,
        dependency: [isChild]
    })
    // *********************************************************************************************************************
    const { req: getChildDetails, res: childDetails_res } = useFetch({
        endpoint: 'incidentType/getsubIncidentChildTypePagination',
        method: 'post',
        type: 'raw',
        // selfCall: isChild ? id : false,
        dependency: [isChild]
    })
    //  *********************************************************************************************************************
    const { req: addIncidentType } = useFetch({
        endpoint: "incidentType/addSubIncidentType",
        method: "post",
        type: "raw",
        toast: true
    })
    // *********************************************************************************************************************
    const { req: addSubIncidentTypeChild } = useFetch({
        endpoint: "incidentType/addSubIncidentTypeChild",
        method: "post",
        type: "raw",
        toast: true
    })
    // *********************************************************************************************************************
    const { req: updateSubIncidentType } = useFetch({
        endpoint: "incidentType/updateSubIncidentType",
        method: "put",
        type: "raw",
        toast: true
    })
    // *********************************************************************************************************************
    const { req: updateSubIncidentTypeChild } = useFetch({
        endpoint: "incidentType/subIncidentTypeChildEditController",
        method: "put",
        type: "raw",
        toast: true
    })
    // *********************************************************************************************************************
    const { req: deleteSubIncidentType } = useFetch({
        endpoint: "incidentType/deleteSubIncidentType",
        method: "delete",
        type: "params",
        toast: true
    })
    // *********************************************************************************************************************
    const { req: deleteSubIncidentTypeChild } = useFetch({
        endpoint: "incidentType/deleteIncidentTypeChild",
        method: "delete",
        type: "params",
        toast: true
    })
    // *********************************************************************************************************************
    let details = isChild ? childDetails_res?.data : details_res?.data
    let pageDetails = isChild ? childDetails_res?.page : details_res?.page
    // *********************************************************************************************************************

    const { filterData, filterHandler, F_navigate, initialFilter } = useFilter({
        initialFilter: { page: 0, perPage: 10, incident_type_id: id },
        Fetch: isChild ? getChildDetails : getDetails
    })

    async function addHandler() {
        let { close, cancel, m_inputs } = await m_Add_Edit_IncidentType.show()
        if (close || cancel) return
        let obj = {
            incident_type: id,
            sub_incident_type_en: m_inputs?.sub_incident_type_en,
            sub_incident_type_ar: m_inputs?.sub_incident_type_ar,
        }
        let childObj = {
            sub_incident_type: id,
            sub_incident_type_child_en: m_inputs?.sub_incident_type_child_en,
            sub_incident_type_child_ar: m_inputs?.sub_incident_type_child_ar,
        }
        isChild
            ? addSubIncidentTypeChild(childObj, () => getChildDetails(initialFilter))
            : addIncidentType(obj, () => getDetails(initialFilter))
    }

    async function editHandler(data) {
        console.log({ data })
        let { close, cancel, m_inputs } = await m_Add_Edit_IncidentType.show({ m_inputs: data, isEdit: true })
        if (close || cancel) return
        let obj = {
            subIncidentTypeId: data._id,
            sub_incident_type_en: m_inputs?.sub_incident_type_en,
            sub_incident_type_ar: m_inputs?.sub_incident_type_ar,
        }
        let childObj = {
            subIncidentTypeChild_id: data._id,
            sub_incident_type_child_en: m_inputs?.sub_incident_type_child_en,
            sub_incident_type_child_ar: m_inputs?.sub_incident_type_child_ar
        }
        isChild
            ? updateSubIncidentTypeChild(childObj, () => getChildDetails(initialFilter))
            : updateSubIncidentType(obj, () => getDetails(initialFilter))
    }

    async function deleteHandler(id) {
        let { cancel } = await Modals.Confirm({ action: 'Delete' })
        if (cancel) return
        isChild
            ? deleteSubIncidentTypeChild(id, () => getChildDetails(initialFilter))
            : deleteSubIncidentType(id, () => getDetails(initialFilter))
    }

    return (
        <Page>

            <Page.Header>
                <Title title={`${isChild ? 'Child' : 'Sub'} Incident Types`} />
                <Breadcrumb content={[
                    { path: '/settings', name: 'Settings' },
                    { path: '/settings/incident_types', name: 'Incident Types' },
                    { path: isChild ? -1 : '', name: location.state?.incident_type?.['incident_type' + _lang] },
                    (location.state?.sub_incident_type?.['sub_incident_type' + _lang] ? { path: '', name: location.state?.sub_incident_type?.['sub_incident_type' + _lang] } : null),
                ].filter(a => a)} />
                <Button width={210} content={`Add ${isChild ? 'Child' : 'Sub'} Incident Type`} css={1.1} icon={'/assets/icons/plus.svg'} reverse onClick={addHandler} />
            </Page.Header>

            <Page.Body>

                <Table
                    css={1}
                    _css={{ search: 2, pagination: 1, entries: 1 }}
                    titles={[
                        { title: 'Sl No', key: 'sl_no' },
                        { title: 'Incident Type (EN)', key: 'incident_type_en', sort: true },
                        { title: 'Incident Type (AR)', key: 'incident_type_ar', sort: true },
                        { title: 'Action', key: 'action', },
                    ]}
                    content={
                        details?.map(((a, i) => {
                            return {
                                sl_no: i + 1,
                                incident_type_en: a.sub_incident_type_child_en || a.sub_incident_type_en,
                                incident_type_ar: a.sub_incident_type_child_ar || a.sub_incident_type_ar,
                                action: <Actions buttons={{ subs: '/assets/icons/subitem.svg', edit: '/assets/icons/edit.svg', delete: '/assets/icons/delete.svg' }}
                                    tooltip={{ subs: 'Sub Item' }}
                                    visibility={{ subs: !isChild }}
                                    onEdit={() => editHandler(a)}
                                    onDelete={() => deleteHandler(a._id)}
                                    onSubs={() => { navigate('../subtypes/' + a._id, { state: { child: true, incident_type: location.state.incident_type, sub_incident_type: a } }) }}
                                />,
                            }
                        }))}
                    pageDetails={pageDetails}
                    filterData={filterData}
                    filterHandler={filterHandler}
                />
            </Page.Body>
        </Page>
    )
}
