import React, { useContext, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation, Outlet } from 'react-router-dom'
import { CommonContext, updateSystem } from '.'
import { ModalsContainer } from './_components/ModalsContainer/ModalsContainer'
import { AppLoader, NetworkLost, PageNotFound } from './_components/Others/Others'
import { PopUpContainer } from './_Hooks/useModal'
import config from '../common/config'

const PathCatch = () => {

    const dispatch = useDispatch()
    const location = useLocation()

    const [currRoute, setCurrRoute] = useState('')

    useEffect(() => {
        const path = location.pathname
        const prev = currRoute
        const curr = path
        setCurrRoute(curr)
        dispatch(updateSystem({ key: 'prevRoute', value: { curr, prev } }))
        // eslint-disable-next-line
    }, [location.pathname, localStorage])

    return <Outlet />
}

export const AppContext = React.createContext()

export let clearAppData

export let setPermissions = null

export default function App({ langRoute }) {

    const dir = config.__dir
    const routes = config.__routes

    const [got_call_from_useInitialAPIs, did_get_call_from_useInitialAPIs] = useState(false)

    const [permission, setPermission] = useState(JSON.parse(sessionStorage.getItem('permission')))

    clearAppData = () => {
        setPermission()
        sessionStorage.removeItem('permission')
        did_get_call_from_useInitialAPIs(false)
    }

    let isStart = process.env.REACT_APP_ENV_START === 'TRUE'
    let isDev = process.env.npm_lifecycle_event === 'dev'

    function routeMaker(routes) {

        return routes.map((route, i) => {

            let prs = Object.keys(permission || {})
            let hasPermission = true
            let arr = []

            prs.forEach(k => {
                if (typeof route[k] === 'string') {
                    arr.push(route[k] === permission[k])
                } else if (Array.isArray(route[k])) {
                    arr.push(route[k]?.includes(permission[k]))
                } else if (typeof route[k] === 'boolean') {
                    arr.push(permission[k])
                }
            })

            hasPermission = arr.length > 0 ? arr.some(s => s === true) : true

            if (!hasPermission) return

            let firstValidChild = route.items?.find((item => {
                let cArr = []
                let c_hasPermission = true
                prs.forEach(k => {
                    if (typeof item[k] === 'string') {
                        cArr.push(item[k] === permission[k])
                    } else if (Array.isArray(item[k])) {
                        cArr.push(item[k]?.includes(permission[k]))
                    } else if (typeof item[k] === 'boolean') {
                        cArr.push(permission[k])
                    }
                })
                c_hasPermission = cArr.length > 0 ? cArr.some(s => s === true) : true
                if (c_hasPermission) return true
            }))

            if (isValidArray(route?.items)) {

                let obj = {}
                if (route.wrapper_component) { obj.element = route.wrapper_component }
                else if (route.path && route.component) { obj.path = route.path }
                else if (route.path && !route.component) { obj.path = route.path; route.component = <Navigate to={firstValidChild?.path} /> }
                else if (!route.path && route.component) { obj.element = route.component }

                return <Route key={i} {...obj}>
                    {(route.path && route.component) && <Route key={i} index element={route.component} />}
                    {routeMaker(route.items)}
                    <Route path='*' element={<PageNotFoundGetter />} />
                </Route>

            }
            else return <Route key={i} path={route.path} element={route.component} />
        })
    }

    const PageNotFoundGetter = () => {

        const { setPageNotFound } = useContext(CommonContext)

        useEffect(() => {
            setPageNotFound(true)
            return () => setPageNotFound(false)
        }, [])

    }

    const R = () => {

        const langs =
            (langRoute === undefined) ? ['']
                : langRoute === true ? ['en', 'ar']
                    : ['']

        return (
            <Routes>
                <Route element={<PathCatch />}>
                    {
                        langs.map((lang, i) => {
                            return (
                                <Route path={lang} key={i}>
                                    {isStart && routeMaker(routes)}
                                    {isDev && routeMaker(routes[2].items[0].items)}
                                </Route>
                            )
                        })
                    }
                </Route>
            </Routes>
        )
    }

    setPermissions = (obj) => {
        let str_obj = JSON.stringify(obj)
        let str_prm = JSON.stringify(permission)
        if (!(str_obj === str_prm)) {
            setPermission(obj)
            sessionStorage.setItem('permission', JSON.stringify(obj))
            did_get_call_from_useInitialAPIs(true)
        }
    }

    const AppDiv = ({ children }) => {
        const { language } = useContext(CommonContext)
        return <div id='App' dir={dir} lang={language}>
            {children}
        </div>
    }

    return (
        <AppContext.Provider value={{
            errorPages: {
                offline: <NetworkLost />,
                pageNotFound: <PageNotFound />,
                Loader: <AppLoader />,
            },
            dir,
            permissions: permission,
            setPermissions,
            got_call_from_useInitialAPIs
        }}>
            <AppDiv>
                <PopUpContainer>
                    {
                        isStart
                            ? <Router><R /></Router>
                            : isDev
                                ? <R />
                                : alert('Error\xA0:\xA0Routing\xA0error\xA0reported')
                    }
                </PopUpContainer>
                <ModalsContainer />
            </AppDiv>
        </AppContext.Provider>
    )
}

function isValidArray(item) {
    let status = item?.length > 0
    return status
}