import React from 'react'
import Page from '../../_common/_components/Page/Page'
import Title from '../../_common/_components/Title/Title'
import Breadcrumb from '../../_common/_components/Breadcrumb/Breadcrumb'
import Table from '../../_common/_components/Table/Table'
import TranslationManagement from '../../_common/_pages/TranslationManagement/TranslationManagement'
import useFetch from '../../_common/_Hooks/useFetch'
import Actions from '../../_common/_components/Actions/Actions'
import useFilter from '../../_common/_Hooks/useFilter'
import { Modals } from '../../_common/_components/ModalsContainer/ModalsContainer'

export default function Translation() {

    // *********************************************************************************************************************
    const { res: allTexts_res } = useFetch({
        endpoint: 'translate/getTexts',
        method: 'post',
        selfCall: true
    })
    // *********************************************************************************************************************
    const { req: insertText } = useFetch({
        endpoint: 'translate/insertText',
        method: 'post',
        type: 'raw',
    })
    // *********************************************************************************************************************
    const { req: verifyText } = useFetch({
        endpoint: 'translate/verifyText',
        method: 'put',
        type: 'params',
    })
    // *********************************************************************************************************************
    const { req: updateText } = useFetch({
        endpoint: 'translate/updateText',
        method: 'put',
        type: 'raw',
    })
    // *********************************************************************************************************************
    const { req: deleteUnverifiedTexts } = useFetch({
        endpoint: 'translate/deleteUnverifiedTexts',
        method: 'put',
    })
    // *********************************************************************************************************************
    const { req: deleteText } = useFetch({
        endpoint: 'translate/deleteText',
        method: 'delete',
        type: 'params',
    })
    // *********************************************************************************************************************
    const { req: getTexts, res: texts } = useFetch({
        endpoint: 'translate/getFilteredList',
        method: 'post',
        type: 'raw',
    })
    // *********************************************************************************************************************
    const { req: importTranslation } = useFetch({
        endpoint: 'translate/importDynamicExcel',
        method: 'post',
        type: 'formdata',
        toast: true
    })
    // *********************************************************************************************************************
    const { req: exportTranslation } = useFetch({
        endpoint: 'translate/exportDynamicExcel',
        method: 'post',
        type: 'raw',
    })
    // *********************************************************************************************************************
    const allTexts = allTexts_res?.data
    const data = texts?.data
    const pageDetails = texts?.page
    // *********************************************************************************************************************

    const { filterData, filterHandler } = useFilter({
        initialFilter: { page: 0, perPage: 10 },
        Fetch: getTexts
    })

    function addHandler(data) {
        insertText({
            title: data.title,
            english_text: data.english,
            arabic_text: data.arabic,
            is_verified: true
        }, () => getTexts(filterData))
    }

    async function updateHandler(data) {
        const { cancel, m_inputs } = await Modals.Edit_Translation({ data, m_inputs: data })
        if (cancel) return
        updateText({
            title: data.title,
            english_text: m_inputs.english_text,
            arabic_text: m_inputs.arabic_text,
            id: data._id
        }, () => getTexts(filterData))
    }

    function importHandler(e) {
        importTranslation({ user_list: e[0].metaFile })
        getTexts()
    }

    function verifyHandler(item) {
        verifyText(item._id, () => getTexts(filterData))
    }

    function deleteHandler(item) {
        deleteText(item._id, () => getTexts(filterData))
    }

    function resetUnverifiedHandler() {
        deleteUnverifiedTexts({}, () => getTexts(filterData))
    }

    return (
        <Page>
            <Page.Header>
                <Title title='Translation' />
                <Breadcrumb />
            </Page.Header>

            <Page.Body css={1}>
                <Table
                    css={1}
                    _css={{ search: 2, pagination: 1, entries: 1 }}
                    titles={[
                        { title: 'Sl No', key: 'sl_no', width: 150 },
                        { title: 'Title', key: 'title' },
                        { title: 'English', key: 'english' },
                        { title: 'Arabic', key: 'arabic' },
                        { title: 'Action', key: 'action', width: 150 },
                    ]}
                    content={data?.map((text, i) => {
                        return {
                            sl_no: i + 1,
                            title: text.title,
                            english: text.english_text,
                            arabic: text.arabic_text,
                            action: <Actions buttons={{ edit: '/assets/icons/edit.svg' }}
                                onEdit={() => updateHandler(text)}
                            />,
                        }
                    })}
                    filterData={filterData}
                    filterHandler={filterHandler}
                    pageDetails={pageDetails}
                />
            </Page.Body>

            {/* 
            <TranslationManagement
                data={allTexts}
                onImport={importHandler}
                onSave={addHandler}
                onDelete={deleteHandler}
                onVerify={verifyHandler}
                onClearUnverified={resetUnverifiedHandler}
            />
            */}

        </Page>
    )
}


