import React, { useContext } from 'react'
import './Breadcrumb.scss'
import { useNavigate } from 'react-router-dom'
import useTranslate from '../../../_common/_Hooks/useTranslate'
import { CommonContext, classMaker } from '../..'

export default function Breadcrumb({ className = '', css, content }) {

  const navigate = useNavigate()
  const { t } = useTranslate()

  const { navbarObj } = useContext(CommonContext)

  const pathObj = objGetter()

  function objGetter() {
    if (navbarObj[window.location.pathname.slice(1)] === undefined) {
      return navbarObj[window.location.pathname.replace(/\/[a-zA-Z0-9]*$/, '').slice(1)]
    }
    if (window.location.pathname === '/') {
      return navbarObj['/']
    }
    return navbarObj[window.location.pathname.slice(1)]
  }

  function clickHandler(path, isAuto, index, navObjArr) {
    if (isAuto) {
      if (navObjArr?.length - 1 === index) return
      if (pathObj.paths[index] === '/') { navigate('/'); return }
      navigate('/' + pathObj.paths[index])
    } else {
      path && navigate(path)
    }
  }

  return (
    <span id='Breadcrumb' className={classMaker(className, css, 'Breadcrumb')}>
      {content
        ? content?.map((item, i) => (
          <p key={i} onClick={() => clickHandler(item.path)}>{t(item.name)}</p>
        ))
        : pathObj?.bds?.map((item, i, arr) => {
          return <p key={i} onClick={() => clickHandler(item, true, i, arr)}>{t(item)}</p>
        })
      }
    </span >
  )
}
Breadcrumb.displayName = 'Breadcrumb'