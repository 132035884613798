import React, { Fragment, useEffect, useState } from 'react'
import Title from '../../_common/_components/Title/Title'
import Breadcrumb from '../../_common/_components/Breadcrumb/Breadcrumb'
import Div from '../../_common/_components/Div/Div'
import Page from '../../_common/_components/Page/Page'
import Table from '../../_common/_components/Table/Table'
import Input from '../../_common/_components/Input/Input'
import Grid from '../../_common/_components/Grid/Grid'
import Select from '../../_common/_components/Select/Select'
import Button from '../../_common/_components/Button/Button'
import StatusBox from '../../_common/_components/StatusBox/StatusBox'
import Image from '../../_common/_components/Image/Image'
import { useNavigate, useParams } from 'react-router'
import TitleBar from '../../_common/_components/TitleBar/TitleBar'
import LabelValue from '../../_common/_components/LabelValue/LabelValue'
import RadioButtons from '../../_common/_components/RadioButtons/RadioButtons'
import TextArea from '../../_common/_components/TextArea/TextArea'
import FooterButtons from '../../_common/_components/FooterButtons/FooterButtons'
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import useFetch from '../../_common/_Hooks/useFetch'
import { CALL_BASE_URL, IMG_BASE_URL } from '../../config'
import DateViewer from '../../_common/_components/DateViewer/DateViewer'
import useForm from '../../_common/_Hooks/useForm'
import Files from '../../_common/_components/Files/Files'
import useTranslate from '../../_common/_Hooks/useTranslate'
import FileUpload from '../../_common/_components/FileUpload/FileUpload'
import { ActionConfirmation, ActionsModalHandler, Confirm, ConfirmMessage, CustomerNotAgreedCall, DismissMessage, EditAlternatives, FileViewer, FindCustomer } from '../../common/Modals'
import useFilter from '../../_common/_Hooks/useFilter'
import Flex from '../../_common/_components/Flex/Flex'
import { useSelector } from 'react-redux'
import List from '../../_common/_components/List/List'
import { useLocation } from 'react-router-dom'
import axios from 'axios'
import DatePick from '../../_common/_components/DatePick/DatePick'
import useModal from '../../_common/_Hooks/useModal'
import Checkbox from '../../_common/_components/Checkbox/Checkbox'
import { socket } from '../../components/InitialApis'
import Phone from '../../_common/_components/Phone/Phone'
import { classNameMaker } from '../../helpers'
import Hint from '../../_common/_components/Hint/Hint'
import { API_BASE_URL_LEGACY } from '../../config'

export default function LegacyViewRequest() {

    const navigate = useNavigate()
    const location = useLocation()
    const { id } = useParams()
    const { _lang, lang, t } = useTranslate()

    const [focusValue, setFocusValue] = useState()

    const requestTypes = [
        { label: 'Manual', value: 'manual' },
        { label: 'Call Back', value: 'call_back' },
        { label: 'Voice Mail', value: 'voice_mail' },
        { label: 'Mobile App', value: 'mobile_app' },
        { label: 'Website', value: 'website' },
        { label: 'Email', value: 'email' },
    ]

    const filterItems = [
        { label: 'Device Id', value: 'device_id' },
        { label: 'IP', value: 'ip' },
        { label: 'Phone Number', value: 'phone_number' },
        { label: 'Email', value: 'email' },
        { label: 'Verified Customer', value: 'customer_id' },
    ]

    const m_fileviewer = useModal(<FileViewer />)
    const m_action_confirmation = useModal(<ActionConfirmation />)
    const m_dismiss_message = useModal(<DismissMessage />)
    const m_confirm = useModal(<Confirm />)
    const m_FindCustomer = useModal(<FindCustomer />)
    const m_editAlternatives = useModal(<EditAlternatives />)
    const m_ConfirmMessage = useModal(<ConfirmMessage />)

    const department = useSelector(s => s.department)
    const user = useSelector(s => s.user)
    const statusesObj = useSelector(s => s.statuses)

    useEffect(() => {
        socket.emit('enteredDetailPage', { request: id })
    }, [socket])

    // *********************************************************************************************************************
    const { req: getDetails, res: details_res } = useFetch({
        endpoint: 'request/getRequestDetails',
        method: 'get',
        type: 'query',
        baseUrl : API_BASE_URL_LEGACY,
        headers: { deptid: location.state?.department }
    })
    // *********************************************************************************************************************
    const { req: findCustomer } = useFetch({
        // endpoint: 'request/findCustomerByContactNumber',
        // method: 'post',
        // type: 'raw',
        // privateLoader: true
    })
    // *********************************************************************************************************************
    const { req: changeRequestStatus } = useFetch({
        // endpoint: 'request/changeRequestStatus',
        // method: 'post',
        // type: 'raw',
        // toast: true,
    })
    // *********************************************************************************************************************
    const { req: getCustomerHistory, res: customerHistory_res } = useFetch({
         endpoint: 'request/getCustomerRequestHistory',
         method: 'post',
         type: 'raw',
         privateLoader: true,
         baseUrl : API_BASE_URL_LEGACY,
    })
    // *********************************************************************************************************************
    const { res: countries } = useFetch({
        // endpoint: 'common/getFullCountries',
        // method: 'get',
        // type: 'raw',
        // selfCall: true,
        // privateLoader: true
    })
    // *********************************************************************************************************************
    const { res: incidentTypes_res } = useFetch({
        // endpoint: 'incidentType/getIncidentType',
        // method: 'post',
        // type: 'raw',
        // selfCall: true,
        // privateLoader: true
    })
    // *********************************************************************************************************************
    const { res: forwardDepartments_res } = useFetch({
        // endpoint: 'department/getForwardingDepartments',
        // method: 'get',
        // type: 'raw',
        // selfCall: true,
        // privateLoader: true
    })
    // *********************************************************************************************************************
    const { res: deptUsers_res } = useFetch({
        // endpoint: 'department/getAllUsersByDepartment',
        // method: 'post',
        // type: 'raw',
        // selfCall: { requestId: id },
        // privateLoader: true
    })
    // *********************************************************************************************************************
    const { res: actions_res } = useFetch({
        // endpoint: 'common/getAllStatus',
        // method: 'get',
        // type: 'raw',
        // selfCall: true,
        // privateLoader: true
    })
    // *********************************************************************************************************************
    const { req: uploadFiles } = useFetch({
        // endpoint: 'file/uploadFile',
        // method: 'post',
        // type: 'formdata',
        // toast: false,
    })
    // *********************************************************************************************************************
    const { req: downloadFile } = useFetch({
        // endpoint: 'common/downloadFiles',
        // method: 'post',
        // type: 'raw',
    })
    // *********************************************************************************************************************
    const { req: shareRequest } = useFetch({
        // endpoint: 'request/shareRequest',
        // method: 'post',
        // type: 'raw',
        // toast: true,
    })
    // *********************************************************************************************************************
    const { res: users_to_share_res } = useFetch({
        // endpoint: 'department/getAllNormalUserByDepartment',
        // method: 'post',
        // type: 'raw',
        // selfCall: { requestId: id },
        // privateLoader: true
    })
    // *********************************************************************************************************************
    const { req: getCurrentStatusForSubmit } = useFetch({
        // endpoint: 'getCurrentStatus',
        // method: 'post',
        // type: 'raw'
    })
    // *********************************************************************************************************************
    const { req: getIncidentDetails, res: incidentTypeDetails } = useFetch({
        // endpoint: "incidentType/getIncidentTypeDetail",
        // type: 'params',
        // method: "get",
        // selfCall: details_res?.data?.incident_type?._id,
        // dependency: [details_res?.data?.incident_type?._id],
        // privateLoader: true
    })
    // *********************************************************************************************************************
    const { req: getChildIncidentTypes, res: childIncidentTypes_res } = useFetch({
        // endpoint: 'incidentType/getSubIncidentTypeByChildIncidentType',
        // method: 'get',
        // type: 'params',
        // selfCall: details_res?.data?.sub_incident_type?.sub_incident_type_id,
        // dependency: [details_res?.data?.sub_incident_type?.sub_incident_type_id],
        // toast: false,
    })
    // *********************************************************************************************************************
    const subincidentTypes = incidentTypeDetails?.data?.subIncidentTypeData
    let childIncidentTypes = childIncidentTypes_res?.data
    // *********************************************************************************************************************
    let details = details_res?.data
    let isApprovalRequired = details?.approvalRequired
    let requestHistory = details_res?.data.requestHistory
    let isAssignedtoAnotherUserNotThisUser = details?.status?.handle_key === 'assigned' && !details?.assigned_to.includes(user._id) && user.role === 'user'
    let can_act = (!['forward', 'need_info', 'escalated', 'waiting_approval', 'closed', 'resolved'].includes(details?.status?.handle_key)) && !isAssignedtoAnotherUserNotThisUser
    let can_edit = (!['forward', 'need_info', 'closed', 'resolved', 'escalated', 'waiting_approval'].includes(details?.status?.handle_key)
        && (department?.handle_key === "customer_service" || (details?.status?.handle_key === 'draft' && details?.created_by === user._id))
        && !isApprovalRequired)
        && !isAssignedtoAnotherUserNotThisUser
    let customerHistory = customerHistory_res?.data
    let incidentTypes = [...(incidentTypes_res?.data || [])]
    incidentTypes?.sort((a, b) => {
        if (b.handle_key === 'other') return -1
        else return 0
    })
    let customerHistoryPageDetails = customerHistory_res?.page
    let forwardDepartments = forwardDepartments_res?.data
    let deptUsers = deptUsers_res?.data
    let actions = actions_res?.data
    let users_to_share = users_to_share_res?.data
    // *********************************************************************************************************************
    let isUser = user.role === 'user'
    let isManager = user.is_manager
    let isSubAdmin = user.is_sub_admin
    let isSuperAdmin = user.is_admin
    let isManger_notIn_CS = user.is_manager && department.handle_key !== "customer_service"
    let isSubAdmin_notIn_CS = user.is_sub_admin && department.handle_key !== "customer_service"
    let isSuperAdmin_notIn_CS = user.is_admin && department.handle_key !== "customer_service"
    let is_CS_User = user.role === 'user' && department.handle_key === "customer_service"
    let isNon_CS_User = user.role === 'user' && department.handle_key !== "customer_service"
    let isManger_in_CS_dept = user.is_manager && department.handle_key === "customer_service"
    let isSubAdmin_in_CS_dept = user.is_sub_admin && department.handle_key === "customer_service"
    let isSuperAdmin_in_CS_dept = user.is_admin && department.handle_key === "customer_service"
    let isCreator = details?.status?.request_status === 'Creator'
    // *********************************************************************************************************************
    let statuses = {}
    let actionsForCurrentUser = actions?.filter(a => {
        statuses[a.handle_key] = a._id
        if (isSuperAdmin_notIn_CS || isSubAdmin_notIn_CS || isManger_notIn_CS)
            return ['resolved', 'forward', 'closed', 'processing', 'assigned', 'return', 'escalated'].includes(a.handle_key)
        if (isManger_in_CS_dept || isSubAdmin_in_CS_dept || isSuperAdmin_in_CS_dept)
            return ['forward', 'closed', 'processing', 'assigned', 'escalated'].includes(a.handle_key)
        if (is_CS_User)
            return ['forward', 'closed', 'processing', 'escalated'].includes(a.handle_key)
        if (isNon_CS_User)
            return ['forward', 'closed', 'processing', 'escalated', 'resolved', 'return', 'share'].includes(a.handle_key)
    })
    actionsForCurrentUser = actionsForCurrentUser?.filter(a => a.handle_key === 'escalated' ? department.handle_key !== 'escalate' : true)
    if (details?.is_draft) {
        actionsForCurrentUser.push(statusesObj.delete)
        actionsForCurrentUser.push(statusesObj.draft)
    }
    let isReopenAction = !isUser && ['closed', 'resolved'].includes(details?.status?.handle_key) && department._id === details?.department
    // *********************************************************************************************************************

    const BtnConditions = {
        comment: (can_act && !isCreator) || isReopenAction,
        attach_file: (can_act && !isCreator) || isReopenAction,
        reopen: isReopenAction,
        select: can_act && !isApprovalRequired && !['closed', 'resolved'].includes(details?.status?.handle_key) && !isCreator,
        save: can_act && !isApprovalRequired && !['closed', 'resolved'].includes(details?.status?.handle_key) && !isCreator,
        close: can_act && !isApprovalRequired,
        reject: can_act && (isApprovalRequired || false) && (isManager || isSubAdmin || isSuperAdmin),
        approve: can_act && (isApprovalRequired || false) && (isManager || isSubAdmin || isSuperAdmin),
    }
    // *********************************************************************************************************************

    const { setInputs, inputs, formPlug, validate, filer, inputHandler, setErrors } = useForm({
        customer_alternate_email: [],
        customer_alternate_contact: [],
        customer_contact_code: {
            "_id": "667e5754a017a0408a9f1f5b",
            "country": "United Arab Emirates",
            "handle_key": "united_arab_emirates",
            "code": "+971",
            "flag": "united_arab_emirates.svg"
        },
        customer_detail_customer_contact_code: {
            "_id": "667e5754a017a0408a9f1f5b",
            "country": "United Arab Emirates",
            "handle_key": "united_arab_emirates",
            "code": "+971",
            "flag": "united_arab_emirates.svg"
        },
    }, { fileUploader: uploadFiles })

    const { filterData, filterHandler, F_navigate, setFilterData, customFilter } = useFilter({
        initialFilter: { perPage: 10, customers_filter: filterItems, requestId: id },
        Fetch: (f) => getCustomerHistory({ ...f, customers_filter: [...f.customers_filter]?.map(fi => fi.value) }),
        // noInitialCall: true
    })

    useEffect(() => {
        getDetails({ id: id, customer_history: location.state?.customer_history }, async (res) => {
            if (res.code === 403) {
                navigate('/incident/requests')
                return
            }
            let data = res.data
            // let filter = setFilterData({
            // phone_number: data?.customer_contact,
            // email: data?.customer_email,
            // device_id: data?.device_id,
            // requestId: id
            // })
            // getCustomerHistory(filter)
            //const customerByDevice = details?.is_anonymous ? await getCustomerByDeviceId({ device_id: data?.device_id }) : {}
            setInputs(s => ({
                ...s,
                // customer_contact: data?.customer_contact || customerByDevice?.data?.customer_contact,
                // customer_contact_code: data?.customer_contact_code || customerByDevice?.data?.customer_contact_code || s.customer_contact_code,
                // customer_email: data?.customer_email || customerByDevice?.data?.customer_email,
                // customer_gender: data?.customer_gender || customerByDevice?.data?.customer_gender,
                // customer_name: data?.customer_name_en || customerByDevice?.data?.customer_name_en,
                // customer_name_ar: data?.customer_name_ar || customerByDevice?.data?.customer_name_ar,
                // customer_nationality: data?.customer_nationality || customerByDevice?.data?.customer_nationality,
                // incident_file: data?.incident_file.map((f, i) => {
                //     return {
                //         url: `${IMG_BASE_URL}request/${f}`,
                //         name: f
                //     }
                // }),
                // incident_type: data?.incident_type,
                // sub_incident_type: data?.sub_incident_type,
                // child_incident_type: data?.sub_incident_child_type,
                // incident_ref_id: data?.incident_ref_id,
                // incident_date: data?.incident_date ? new Date(data?.incident_date) : '',
                description: data?.descripition,
                customer_detail_customer_name_en: data?.customer_detail?.customer_name_en,
                customer_detail_customer_contact: data?.customer_detail?.customer_contact,
                customer_detail_customer_contact_code: data?.customer_detail?.customer_contact_code || s.customer_detail_customer_contact_code,
                customer_detail_customer_email: data?.customer_detail?.customer_email,
                customer_detail_customer_nationality: data?.customer_detail?.customer_nationality,
                customer_detail_customer_gender: data?.customer_detail?.customer_gender,
                customer_detail_is_verified: data?.customer_detail?.is_verified_phone_number,
                customer_alternate_contact: data?.customer_detail?.customer_alternate_contact || [],
                customer_alternate_email: data?.customer_detail?.customer_alternate_email || [],
            }))
        })
    }, [location.pathname])


    async function emailPhoneVerificationHandler(res, type) {
        if (res.errorCode === 1001) {
            const { yes, no, close, change_email, change_number, use_existing, alternative_email, alternative_number } = await m_FindCustomer.show({ details: res, type, inputs })
            if (close) return
            let existingCustomerData = {
                customer_detail_customer_name_en: res.data?.customer_name_en,
                customer_detail_customer_email: res.data?.customer_email,
                customer_detail_customer_contact: res.data?.customer_contact,
                // customer_contact_code: res.data?.customer_contact_code,
                customer_detail_customer_nationality: res.data?.customer_nationality,
                customer_detail_customer_gender: res.data?.customer_gender?.toLowerCase(),
                customer_detail_is_verified: res.data?.is_verified_phone_number,
            }
            if (yes || use_existing) {
                setInputs(s => ({
                    ...s,
                    ...existingCustomerData,
                    customer_detail_id: res.data?._id,
                    customer_alternate_contact: res.data?.customer_alternate_contact,
                    customer_detail_customer_contact_code: res.data?.customer_contact_code,
                    customer_alternate_email: res.data?.customer_alternate_email,
                    customer_detail_is_verified: res.data?.is_verified_phone_number,
                }))
            }
            if (no) {
                if (type === 'phone') {
                    document.getElementsByClassName('customer_phone')[0]?.scrollIntoView({ block: 'center' })
                    setErrors(s => ({ ...s, customer_detail_customer_contact: 'Change phone number' }))
                } else {
                    document.getElementsByClassName('customer_email')[0]?.scrollIntoView({ block: 'center' })
                    setErrors(s => ({ ...s, customer_detail_customer_email: 'Change email' }))
                }
            }
            if (change_email) {
                document.getElementsByClassName('customer_email')[0]?.scrollIntoView({ block: 'center' })
                setErrors(s => ({ ...s, customer_detail_customer_email: 'Change email' }))
            }
            if (change_number) {
                document.getElementsByClassName('customer_phone')[0]?.scrollIntoView({ block: 'center' })
                setErrors(s => ({ ...s, customer_detail_customer_contact: 'Change phone number' }))
            }
            if (alternative_email) {
                setInputs(s => ({
                    ...s,
                    ...existingCustomerData,
                    customer_detail_id: res.data?._id,
                    customer_alternate_contact: res.data?.customer_alternate_contact,
                    customer_alternate_email: [...s.customer_alternate_email, s.customer_detail_customer_email],
                    customer_new_alternate_email: s.customer_detail_customer_email
                }))
            }
            if (alternative_number) {

                setInputs(s => ({
                    ...s,
                    ...existingCustomerData,
                    customer_detail_id: res.data?._id,
                    customer_alternate_email: res.data?.customer_alternate_email,
                    // customer_alternate_contact: [...s.customer_alternate_contact, s.customer_detail_customer_contact],
                    customer_alternate_contact: [...res.data?.customer_alternate_contact, {
                        code: s.customer_detail_customer_contact_code?.code || s.customer_detail_customer_contact_code,
                        number: s.customer_detail_customer_contact
                    }],
                    customer_new_alternate_contact: s.customer_detail_customer_contact
                }))
            }
        }
    }
    function customerFindHandler(value, type, cb) {
        if (((value.number || value) === focusValue) && !cb) return
        let obj = {
            customer_contact: inputs.customer_detail_customer_contact || (type === 'phone' ? value?.number : ''),
            code: inputs?.customer_detail_customer_contact_code?.code || inputs?.customer_detail_customer_contact_code,
            email: inputs.customer_detail_customer_email || (type === 'email' ? value : ''),
            checking_for: type
        }
        setErrors(s => ({ ...s, customer_detail_customer_contact: '', customer_detail_customer_email: '' }))
        if (cb && type === 'phone') { obj = { customer_contact: value.number, code: value.code, checking_for: type } }
        if (cb && type === 'email') { obj = { email: value, checking_for: type } }
        findCustomer(obj, async (res) => {
            cb
                ? cb(res)
                : emailPhoneVerificationHandler(res, type)
        })
    }

    function callHandler(number, ticket_no) {
        const formdata = new FormData()
        formdata.append('phone_number', number.replace('+', '00'))
        formdata.append('agent_id', user?.phone_number)
        formdata.append('ticket_id', ticket_no)
        formdata.append('agent_name', user?.employee_id)
        formdata.append('type', 'incident_request')
        axios.post(CALL_BASE_URL, formdata)
    }

    function updatesGetter(item, type, isVerified) {
        let { incident_type_ar, incident_type_en, sub_incident_type_en, sub_incident_type_ar, ...restKeys } = item.updated_fields?.[type] || {}
        let key = {
            ...restKeys,
            ...((type === 'incident_details' && item.updated_fields?.incident_details?.incident_type_en) ? { incident_type: lang === 'en' ? incident_type_en : incident_type_ar } : {}),
            ...((type === 'incident_details' && item.updated_fields?.incident_details?.sub_incident_type_en) ? { sub_incident_type: lang === 'en' ? sub_incident_type_en : sub_incident_type_ar } : {})
        }
        if (isVerified) {
            key = item.updated_fields_verified?.[type]
        }
        let obj = {
            customer_contact: 'Customer Contact',
            customer_contact_code: 'Country Code',
            customer_email: 'Customer Email',
            customer_gender: 'Customer Gender',
            customer_name_en: 'Customer Name',
            customer_nationality: 'Customer Nationality',
            incident_date: 'Incident Date',
            descripition: 'Descripition',
            incident_type: 'Incident Type',
            sub_incident_type: 'Sub Incident Type',
            is_verified_phone_number: 'Customer Verification',
            customer_alternate_contact: 'Customer Alternate Contact',
            customer_alternate_email: 'Customer Alternate Email',
            customer_contact_number: 'Customer Phone Number'
        }

        function valueFormatter(name, val) {
            switch (name) {
                case 'customer_alternate_contact':
                    return val?.map(v => <p>{v.code + " " + v.number}</p>)
                case 'is_verified_phone_number':
                    return val === 'true' ? 'Verified' : val === 'false' ? 'Unverified' : ''
                case 'customer_alternate_email':
                    return val?.map(v => <p>{v}</p>)
                case 'customer_gender':
                    return t(val)
                default:
                    return val
            }
        }

        const res = Object.keys(key || {})
            .reduce((acc, crr) => {
                let oldval = key[crr]?.old_value
                let newval = key[crr]?.new_value
                return [
                    ...acc,
                    {
                        field: obj[crr],
                        old: valueFormatter(crr, oldval),
                        new: valueFormatter(crr, newval),
                    }
                ]
            }, [])
        return res
    }

    async function alternativesEditHandler({ type, data }) {
        const { submit, m_inputs } = await m_editAlternatives.show({ type, data, inputs, customerFindHandler })
        if (submit) {
            setInputs(s => ({ ...s, [type === 'phone' ? 'customer_alternate_contact' : 'customer_alternate_email']: Object.values(m_inputs) }))
        }
    }

    function df(value, field) {
        return (field === 'Incident Date')
            ? (value ? <DateViewer dateTime={value} /> : undefined)
            : value
    }

    function fileDownloadHandler(e) {
        m_fileviewer.show({
            value: e,
            canDownload: user.download_files,
            downloader: () => downloader(e.url, e.name)
        })
    }

    function focusHandler(value) {
        setFocusValue(value)
    }

    function downloader(url, name) {
        fetch(url)
            .then(response => response.blob())
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url
                link.download = name
                link.click();
            })
        downloadFile({
            requestId: id,
            filePath: url,
            type: 'incident_request'
        })
    }

    let pendingFilesCounts = details?.file_ids?.length - details?.success_ids?.length - details?.error_ids?.length
    let errorFileCounts = details?.error_ids?.length
    let successFileCounts = details?.success_ids?.length

    return (
        <Page>
            <Page.Header>
                <Title title='Requests' />
                <Breadcrumb />
                <Button height={'40px'} width={'40px'} iconWidth={'23px'} className='anonymous'
                    icon={'/assets/icons/anonymous.svg'} content='' condition={details?.is_anonymous}
                />
            </Page.Header>

            <Page.Body css={1} >
                <Div css={1.1}>
                    <TitleBar css={1} title='Request Details' toggleButton titleEndElement={<StatusBox className='tlb_status' css={1} status={details?.status?.request_status} />} >

                        <Grid columns={4}>
                            <LabelValue label='Ticket Number' value={details?.ticket_no} />
                            <LabelValue label='Created Date & Time' value={<DateViewer dateTime={details?.createdAt} />} />
                            <LabelValue label='Request Type' value={<StatusBox css={2} status={details?.request_type} />} />
                            <LabelValue css={1} label='Customer Contact' value={details?.customer_contact ? ((details?.customer_contact_code || '+971') + ' ' + details?.customer_contact) : ''} isHyperlink className='customer_contact'
                                valueIcon={department.handle_key !== "customer_service" ? '' : '/assets/icons/phone.svg'} valueIconHint={'Call Customer'}
                                onIconClick={async () => {
                                    if (!details?.agreeMe) {
                                        const { cancel, close } = await m_ConfirmMessage.show({ message: "The customer doesn’t wish to be contacted. Proceed with call ?" })
                                        if (cancel || close) return
                                    }
                                    callHandler((details?.customer_contact_code || '+971') + details?.customer_contact, details?.ticket_no)
                                }}
                            />
                        </Grid> <hr />
                        
                       
                        <hr />
                         <Grid columns={5}>
                                <LabelValue label='Name' value={details?.customer_name_en} />
                                <LabelValue label='Phone Number' value={details?.customer_contact ? ((details?.customer_contact_code || '+971') + ' ' + details?.customer_contact) : '-'} className='contact_value' />
                                <LabelValue label='Email' value={details?.customer_email} />
                                <LabelValue label='Nationality' value={details?.customer_nationality?.name} />
                                <LabelValue label='Gender' value={details?.customer_gender} className={'gender'} />
                            </Grid>
                            <Grid columns={1} className='mt-3'>
                                <LabelValue label='Description' value={details?.message} />
                            </Grid>
                        
                    </TitleBar>
                </Div>

               

                <Div css={1.1} >
                    <TitleBar css={1} title='Customer History' toggleButton defaultToggle='hide'>
                        <Table
                            css={1.1}
                            _css={{ search: 2, pagination: 1, entries: 1 }}
                            className={'customer_history_table'}
                            boxwidthReduce={200}
                            hideFilters
                            titles={[
                                //{ title: 'Ticket Number', key: 'ticket_no', sort: true },
                                { title: 'Created Date', key: 'created_date', sort: true },
                                { title: 'Handled By', key: 'agent_name', sort: true },
                                //{ title: 'Request Type', key: 'request_type', sort: true },
                                //{ title: 'Incident Type', key: 'incident_type', sort: true },
                                //{ title: 'Email', key: 'email', sort: true, last: 1 },
                                //{ title: 'Phone', key: 'phone', sort: true, dir: 'ltr', d_align: 'right', last: 2 },
                                { title: 'Messages', key: 'assigned_department', sort: true },
                                //{ title: 'Status', key: 'status', sort: true },
                            ]}
                            content={customerHistory?.history?.map(h => ({
                                id: h._id,
                                //ticket_no: h.ticket_no,
                                created_date: <DateViewer dateTime={h.createdAt} dateOnly />,
                                agent_name: h.firstname,
                               // request_type: <StatusBox css={2} status={h.request_type} />,
                               // incident_type: h.incident_type?.['incident_type' + _lang],
                                //phone: h?.customer_contact ? ((h?.customer_contact_code || '+971') + ' ' + h?.customer_contact) : '-',
                                //email: h?.customer_email,
                                assigned_department: h?.message,
                                //status: <StatusBox css={1} status={h.status?.request_status} />,
                                additionalDetails: <div className='customer_history_incident_details'>
                                    <Flex mb={0} columns={5} gap={15} _minWidth={200}>
                                        <LabelValue label={'Agent Email'} value={h?.handledby?.email} mxWidth={300} />
                                        <LabelValue label={'Department'} value={h?.handledby?.department?.department_name_en} />
                                    </Flex>
                                    
                                    
                                </div>
                            }))}
                            // onRowClick={(e) => {
                            //     F_navigate(`/incident/requests/view_request/${e.id}`)
                            //     window.scrollTo({ top: 0, left: 0, behavior: 'instant' })
                            // }}
                            filterData={filterData}
                            filterHandler={filterHandler}
                            pageDetails={customerHistoryPageDetails}
                        />
                    </TitleBar>
                </Div>

                <FooterButtons>
                    <Button
                        right
                        css={1}
                        content="Back"
                        icon="/assets/icons/back.svg"
                        reverse
                        iconWidth="15px"
                        onClick={() => navigate(location.state?.back === "myrequests" ? 'legacyrequests/legacyrequests' : -1)}
                    />
                </FooterButtons>

                
            </Page.Body>

        </Page>
    )
}
