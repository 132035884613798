export const API_BASE_URL = "https://al-ameen-api.shtdevops.xyz/"
export const IMG_BASE_URL = "https://al-ameen-api.shtdevops.xyz/"

// export const CALL_BASE_URL = "http://172.18.54.41/makecall/"

export const CALL_BASE_URL = `${window.location.origin}/makecall/`
//  export const API_BASE_URL =  `${window.location.origin}/crm-backend/`
//  export const IMG_BASE_URL = "/mnt/data/crm/uploads/"
//  export const API_BASE_URL_LEGACY = `${window.location.origin}/legacycrm-backend/`

//export const IMG_BASE_URL = `${window.location.origin}/uploads/`

// export const API_BASE_URL = "http://192.168.1.233:8041/"
// export const IMG_BASE_URL = "http://192.168.1.233:8041/"

//  export const API_BASE_URL = "http://localhost:8041/"
//  export const IMG_BASE_URL = "http://localhost:8041/"
 export const API_BASE_URL_LEGACY = "http://localhost:8045/"

// export const API_BASE_URL = "http://24.199.89.100/crm-backend/"
// export const IMG_BASE_URL = "http://24.199.89.100/crm-backend/"

// export const API_BASE_URL = `https://${window.location.origin}/crm-backend/`
// export const IMG_BASE_URL = `https://${window.location.origin}/crm-backend/mnt/data/crm/uploads/`
// export const CALL_BASE_URL = `http://172.18.54.41/makecall/`
